import {useEffect, useState} from 'react';
import './App.css';
import SearchIcon from './search.svg';
import MovieCard from './MovieCard';

const App = () => {

const API_KEY = '3e6e40a1';
const API_URL = 'https://www.omdbapi.com?apikey='+ API_KEY;

const searchMovies = async (title) => {
    const resp = await fetch(`${API_URL}&s=${title}`);
    const data = await resp.json();
    setMovies(data.Search);
}

const [searchTitle, setSearchTitle] = useState("");
const [movies, setMovies] = useState([]);

 useEffect(() => {
     searchMovies('Hulk');
 },[]);



return (
    <div className='app'>
        <h1>Movie Finder</h1>
        <div className='search'>
            <input placeholder='Search for Movies'
                onChange={(e) => {setSearchTitle(e.target.value)}}
            />
            <img src={SearchIcon}
                alt = "search"
                onClick={(e) => {searchMovies(searchTitle)}}
            />
            
        </div>

    {
        movies?.length > 0
        ? (
            <div className='container'>
                {movies.map(movie => 
                <MovieCard movie={movie} />
                )}
            </div>
        ):(
            <div className='empty'>
                <h2>No Movies found</h2>
            </div>
        )
    }

    </div>
);

}

export default App;
